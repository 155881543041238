<template>
  <div>
    <v-container grid-list-md fluid ref="contrato">
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-toolbar flat class="transparent">
            <v-breadcrumbs :items="breadcrumbs" divider=">">
              <template slot="item" slot-scope="props">
                <a
                  @click="goHref(props.item)"
                  :disabled="props.item.disabled"
                >{{ props.item.text.toUpperCase() }}</a>
              </template>
            </v-breadcrumbs>
          </v-toolbar>

          <v-stepper v-model="step" :non-linear="parcelas.length > 0 ? true : false">
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :editable="parcelas.length > 0 ? true : false"
                :complete="parcelas.length > 0 ? true : false"
              >Cliente</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                :editable="parcelas.length > 0 ? true : false"
                :complete="parcelas.length > 0 ? true : false"
              >Segurados</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                :editable="parcelas.length > 0 ? true : false"
                :complete="parcelas.length > 0 ? true : false"
              >Faturamento</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <ContratoCliente :finalizado="parcelas.length > 0 ? true : false" />
              </v-stepper-content>

              <v-stepper-content step="2">
                <ContratoSegurado :finalizado="parcelas.length > 0 ? true : false" />
              </v-stepper-content>

              <v-stepper-content step="3">
                <ContratoFaturamento :finalizado="parcelas.length > 0 ? true : false" />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

const ContratoFaturamento = () => import('./ContratoFaturamento')
const ContratoCliente = () => import('./ContratoCliente')
const ContratoSegurado = () => import('./ContratoSegurados')
const faturamentoModule = () => import('./../../services/faturamento')

export default {
  name: 'ContratosTabs',
  components: {
    ContratoSegurado,
    ContratoCliente,
    ContratoFaturamento
  },
  data () {
    return {
      parcelas: [],
      tabs: 0,
      step: 1,
      breadcrumbs: [
        {
          text: 'Lista de vendas',
          disabled: false,
          href: '/vendas'
        },
        {
          text: 'Venda',
          disabled: true,
          href: '/vendas/detalhes'
        },
      ]
    }

  },
  created () {
    this.$eventHub.$on('contratoDetalhes-goStep', this.goStep)
    this.$eventHub.$on('contratoDetalhes-getParcelas', this.getParcelas)
    this.getParcelas()
  },
  beforeDestroy () {
    this.$eventHub.$off('contratoDetalhes-goStep')
    this.$eventHub.$off('contratoDetalhes-getParcelas')

  },
  mounted () {

  },
  methods: {
    goStep (value) {
      this.step = value
    },
    goHref (item) {
      if (!item.disabled) {
        this.$router.push(item.href)
      }
    },
    async getParcelas () {
      if (this.$route.params.venda) {
        let faturamentoService = await faturamentoModule()
        let resp = await faturamentoService.getParcelasByContrato(this.$axios, this.$route.params.venda)
        if (resp.status === 200 && resp.data) {
          this.parcelas = resp.data
        } else {
          this.parcelas = []
        }
      }
    }
  }

}
</script>